import React, { useRef, useState, useEffect } from "react";

const TrackVisualizerCanvas = ({ width, height, audioRef, audioBuffer }) => {
  const canvasRef = useRef(null);
  const currentTrackTime = useRef(0);

  const drawWaveform = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const trackTime = audioRef.current.currentTime;
    const { length } = audioBuffer.getChannelData(0);
    const step = Math.ceil(length / width);
    const amp = height / 2;

    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    ctx.scale(devicePixelRatio, devicePixelRatio);

    ctx.clearRect(0, 0, width, height);

    for (let i = 0; i < width; i++) {
      let min = 1.0;
      let max = -1.0;
      for (let j = 0; j < step; j++) {
        const datum = audioBuffer.getChannelData(0)[i * step + j];
        if (datum < min) min = datum;
        if (datum > max) max = datum;
      }
      if (i <= (trackTime / audioBuffer.duration) * width) {
        ctx.strokeStyle = "#009688";
      } else {
        ctx.strokeStyle = "#C0C0C0";
      }
      ctx.beginPath();
      ctx.moveTo(i, amp - min * amp);
      ctx.lineTo(i, amp - max * amp);
      ctx.stroke();
    }

    currentTrackTime.current = trackTime;
    requestAnimationFrame(drawWaveform);
  };

  console.log("audioBuffer:", audioBuffer);

  useEffect(() => {
    if (audioBuffer) {
      requestAnimationFrame(drawWaveform);
    }
  }, [audioBuffer]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

const TrackVisualizer = ({
  width = 300,
  height = 100,
  audioRef,
  audioBuffer,
  drawingLoader = null
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const svgRef = useRef(null);
  const pathPlayedRef = useRef(null);
  const pathRemainingRef = useRef(null);
  const audioBufferRef = useRef(null);
  const currentTrackTime = useRef(0);
  const [isDrawing, setIsDrawing] = useState(false);

  const drawWaveform = (audioBuffer) => {
    if (!audioBuffer) {
      return;
    }
    setIsDrawing(true);
    const trackTime = currentTrackTime.current;
    const { length } = audioBuffer.getChannelData(0);
    const step = Math.ceil(length / width);
    const amp = height / 2;
    let pathPlayed = `M 0 ${amp}`;
    let pathRemaining = `M ${
      (trackTime / audioBuffer.duration) * width
    } ${amp}`;

    for (let i = 0; i < width; i++) {
      let min = 1.0;
      let max = -1.0;
      for (let j = 0; j < step; j++) {
        const datum = audioBuffer.getChannelData(0)[i * step + j];
        if (datum < min) min = datum;
        if (datum > max) max = datum;
      }
      if (i <= (trackTime / audioBuffer.duration) * width) {
        pathPlayed += ` L ${i} ${amp - min * amp} L ${i} ${amp - max * amp}`;
      } else {
        pathRemaining += ` L ${i} ${amp - min * amp} L ${i} ${amp - max * amp}`;
      }
    }

    if (pathPlayedRef.current) {
      pathPlayedRef.current.setAttribute("d", pathPlayed);
    }
    if (pathRemainingRef.current) {
      pathRemainingRef.current.setAttribute("d", pathRemaining);
    }
    setIsDrawing(false);
  };

  useEffect(() => {
    if (!audioBuffer) {
      return;
    }
    audioBufferRef.current = audioBuffer;
    drawWaveform(audioBuffer);
  }, [audioBuffer]);

  const updateInterval = 250; // Mettre à jour toutes les 1000 millisecondes (1 seconde)
  let lastUpdateTime = 0;

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (!audioBufferRef.current) {
        return;
      }
      const currentTime = Date.now();
      if (currentTime - lastUpdateTime > updateInterval) {
        currentTrackTime.current = audioRef.current.currentTime;
        drawWaveform(audioBufferRef.current);
        lastUpdateTime = currentTime;
      }
    };

    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  return (
    <>
      <svg
        ref={svgRef}
        width={width}
        height={height}
        style={{ display: isDrawing ? "none" : "block" }}
      >
        <path
          ref={pathPlayedRef}
          fill="none"
          stroke="#009688"
          strokeWidth="2"
        />
        <path
          ref={pathRemainingRef}
          fill="none"
          stroke="#C0C0C0"
          strokeWidth="2"
        />
      </svg>
      {isDrawing && drawingLoader}
    </>
  );
};

export default (props) => {
  const overlayRef = useRef(null);
  const lineRef = useRef(null);
  const { width, height, onSeek, audioNode } = props;

  const handleClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const clickPosition = (x / width) * audioNode.mediaElement.duration; // Utilisez la durée de l'élément audio
    onSeek(clickPosition);
  };

  const handleMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    if (overlayRef.current && lineRef.current) {
      overlayRef.current.style.opacity = `1`;
      overlayRef.current.style.width = `${x}px`;
      lineRef.current.style.left = `${x}px`;
      lineRef.current.style.opacity = `1`;
    }
  };

  const handleMouseLeave = () => {
    if (overlayRef.current && lineRef.current) {
      overlayRef.current.style.opacity = `0`;
      overlayRef.current.style.width = `0px`;
      lineRef.current.style.left = `-1px`; // -1px pour cacher la barre
      lineRef.current.style.opacity = `0`;
    }
  };

  return (
    <div style={{ position: "relative", width, height }}>
      <TrackVisualizer {...props} />

      <div
        style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}
        onClick={handleClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div ref={overlayRef} className="overlay" />
        <div ref={lineRef} className="line" />
      </div>
    </div>
  );
};
