import React, { createContext, useContext, useEffect, useState } from "react";

const ChromecastContext = createContext();

export const useChromecast = () => useContext(ChromecastContext);

export const ChromecastProvider = ({
  children,
  currentTrack,
  album,
  isPlaying
}) => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [cast, setCast] = useState(null);

  useEffect(() => {
    setCast(new window.Castjs());
  }, []);

  useEffect(() => {
    if (cast) {
      console.log("cast", cast);
      cast.on("device", (device) => {
        console.log("device", device);
        setDevices((prev) => [...prev, device]);
      });
    }
  }, [cast]);

  console.log("devices", devices);

  const connectToDevice = (device) => {
    setSelectedDevice(device);
  };

  const disconnectDevice = () => {
    if (selectedDevice) {
      cast.close();
      setSelectedDevice(null);
    }
  };

  const playContent = () => {
    if (selectedDevice && isPlaying) {
      const hostUrl = window.location.origin;
      const media = {
        content: `${hostUrl}${currentTrack.audioSrc}`,
        type: "audio/mp3",
        title: currentTrack.title,
        image: `${hostUrl}${album.cover}`
      };
      cast.cast(media);
    }
  };

  useEffect(() => {
    playContent();
  }, [selectedDevice, currentTrack, isPlaying]);

  const contextValue = {
    devices,
    connectToDevice,
    disconnectDevice,
    isConnected: !!selectedDevice
    // Ajouter d'autres états et méthodes au besoin
  };

  return (
    <ChromecastContext.Provider value={contextValue}>
      {children}
    </ChromecastContext.Provider>
  );
};

export default useChromecast;
