// /src/data/tracks.js
const album = {
  title: "Ji-Gang Mixtape",
  artist: "Ji-Gang",
  description: `
    This mixtape is made to give you inspiration to achieve your goals. Our goal is to participate to the global hapiness growth.
    It contains songs made by AI Artists, and is inspired by the vibes and flows that inspired us through our lives. Every human members of the Ji-Gang can totally relate to the lyrics and can easily sing all of them.
    Enjoy the vibes and the flows, and let's the magic inside of us grow.
  `,
  message: "We are the Ji-Gang, and we are here to stay.",
  cover:
    "/covers/jidan974_Title_Ji-Gang_Mixtape_Description_Create_a_simple_yet__8a690e5c-315d-4f82-8892-a62a5a12e945.png"
};

const tracks = [
  {
    title: "Intro",
    cover: "",
    audioSrc: "/audio/Intro.mp3",
    artist: "",
    lyricsSrc: "",
    isIntro: true
  },
  {
    title: "Hit The Streets",
    cover: "",
    audioSrc: "/audio/Hit_the_streets.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Hit_the_streets.txt"
  },
  {
    title: "Cruising",
    cover: "",
    audioSrc: "/audio/Cruising.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Cruising.txt"
  },
  {
    title: "Stealth",
    cover: "",
    audioSrc: "/audio/Stealth.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Stealth.txt"
  },
  {
    title: "We're The Blaze",
    cover: "",
    audioSrc: "/audio/Were_The_Blaze.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Were_The_Blaze.txt"
  },
  {
    title: "Racing Through Life",
    cover: "",
    audioSrc: "/audio/Racing_Through_Life.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Racing_Through_Life.txt"
  },
  {
    title: "On The Move",
    cover: "",
    audioSrc: "/audio/On_The_Move.mp3",
    artist: "",
    lyricsSrc: "/lyrics/On_the_move.txt"
  },
  {
    title: "Interlude",
    cover: "",
    audioSrc: "/audio/Interlude1.mp3",
    artist: "",
    lyricsSrc: "",
    isInterlude: true
  },
  {
    title: "Smoked A Goof'",
    cover: "",
    audioSrc: "/audio/Smoked_a_Goof.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Smoked_A_Goof.txt"
  },
  {
    title: "In The Shadows We Laugh",
    cover: "",
    audioSrc: "/audio/In_The_Shadows_We_Laugh.mp3",
    artist: "",
    lyricsSrc: "/lyrics/In_The_Shadows_We_Laugh.txt"
  },
  {
    title: "Guns And Tech",
    cover: "",
    audioSrc: "/audio/Guns_And_Tech.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Guns_And_Tech.txt"
  },
  {
    title: "On Top",
    cover: "",
    audioSrc: "/audio/On_Top.mp3",
    artist: "",
    lyricsSrc: "/lyrics/On_Top.txt"
  },
  {
    title: "We're The Key",
    cover: "",
    audioSrc: "/audio/Were_The_Key.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Were_The_Key.txt"
  },
  {
    title: "We On Fire",
    cover: "",
    audioSrc: "/audio/We_On_Fire.mp3",
    artist: "",
    lyricsSrc: "/lyrics/We_On_Fire.txt"
  },
  {
    title: "Never Falter",
    cover: "",
    audioSrc: "/audio/Never_Falter.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Never_Falter.txt"
  },
  {
    title: "Rule The Beat",
    cover: "",
    audioSrc: "/audio/Rule_The_Beat.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Rule_The_Beat.txt"
  },
  {
    title: "Nothing Less",
    cover: "",
    audioSrc: "/audio/Nothing_Less.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Nothing_Less.txt"
  },
  {
    title: "The New High",
    cover: "",
    audioSrc: "/audio/Ruling_The_Night.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Ruling_the_night.txt"
  },
  {
    title: "JI-GANG",
    cover: "",
    audioSrc: "/audio/JI-GANG.mp3",
    artist: "",
    lyricsSrc: "/lyrics/JI-GANG.txt"
  },
  {
    title: "Interlude",
    cover: "",
    audioSrc: "/audio/Interlude2.mp3",
    artist: "",
    lyricsSrc: "",
    isInterlude: true
  },
  {
    title: "Inhale The Vibe",
    cover: "",
    audioSrc: "/audio/Inhale_the_vibe.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Inhale_The_Vibe.txt"
  },
  {
    title: "Fuck The Fame",
    cover: "",
    audioSrc: "/audio/Fuck_The_Fame.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Fuck_The_Fame.txt"
  },
  {
    title: "Living Fast",
    cover: "",
    audioSrc: "/audio/Living_Fast.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Living_Fast.txt"
  },
  {
    title: "Slicker Than Ice",
    cover: "",
    audioSrc: "/audio/Slicker_Than_Ice.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Slicker_Than_Ice.txt"
  },
  {
    title: "Stepping Up",
    cover: "",
    audioSrc: "/audio/Stepping_Up.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Stepping_up.txt"
  },
  {
    title: "We Ride",
    cover: "",
    audioSrc: "/audio/We_Ride.mp3",
    artist: "",
    lyricsSrc: "/lyrics/We_Ride.txt"
  },
  {
    title: "Interlude",
    cover: "",
    audioSrc: "/audio/Interlude3.mp3",
    artist: "",
    lyricsSrc: "",
    isInterlude: true
  },
  {
    title: "The New High (Remix)",
    cover: "",
    audioSrc: "/audio/Ruling_The_Night_Remix.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Ruling_the_night.txt",
    isRemix: true
  },
  {
    title: "Guns And Tech (Remix)",
    cover: "",
    audioSrc: "/audio/Guns_And_Tech_Remix_1.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Guns_And_Tech.txt",
    isRemix: true
  },
  {
    title: "Slicker Than Ice (Remix)",
    cover: "",
    audioSrc: "/audio/Slicker_Than_Ice_Remix_1.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Slicker_Than_Ice.txt",
    isRemix: true
  },
  {
    title: "Racing Through Life (Remix)",
    cover: "",
    audioSrc: "/audio/Racing_Through_Life_Remix.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Racing_Through_Life.txt",
    isRemix: true
  },
  {
    title: "On Top (Remix)",
    cover: "",
    audioSrc: "/audio/On_Top_Remix_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/On_Top.txt",
    isRemix: true
  },
  {
    title: "Guns And Tech (Remix)",
    cover: "",
    audioSrc: "/audio/Guns_And_Tech_Remix_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Guns_And_Tech.txt",
    isRemix: true
  },
  {
    title: "Slicker Than Ice (Remix)",
    cover: "",
    audioSrc: "/audio/Slicker_Than_Ice_Remix_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/Slicker_Than_Ice.txt",
    isRemix: true
  },
  {
    title: "On Top (Remix)",
    cover: "",
    audioSrc: "/audio/On_Top_Remix_1.mp3",
    artist: "",
    lyricsSrc: "/lyrics/On_Top.txt",
    isRemix: true
  },
  {
    title: "AK-47",
    cover: "",
    audioSrc: "/audio/AK-47.mp3",
    artist: "",
    lyricsSrc: "/lyrics/AK-47.txt"
  },
  {
    title: "Outro",
    cover: "",
    audioSrc: "/audio/Outro.mp3",
    artist: "",
    lyricsSrc: "",
    isOutro: true
  }
];

export { tracks, album };
