import React, { useState, useEffect } from "react";
import { fetchLyrics } from "../services/lyrics";
import "./TrackDetails.css";
import { useMediaPlayer } from "../contexts/MediaPlayerContext";

const TrackDetails = ({ track }) => {
  const [lyrics, setLyrics] = useState("");
  const [blockWidth, setBlockWidth] = useState(0);
  const { audioNode } = useMediaPlayer();

  useEffect(() => {
    if (!track.lyricsSrc) {
      setLyrics("");
      return;
    }
    fetchLyrics(track.lyricsSrc).then((text) => setLyrics(text));
  }, [track]);

  return (
    <div className="track-details">
      {track.cover && (
        <img
          src={track.cover}
          alt={track.title}
          className="track-details-cover"
        />
      )}
      <h2 className="track-details-title">{track.title}</h2>
      <div className="lyrics">{lyrics}</div>
    </div>
  );
};

export default TrackDetails;
