import React, { useEffect, useRef, useState } from "react";
import { tracks, album } from "./data/album";
import "./App.css";
import TrackDetails from "./components/TrackDetails";
import MusicPlayer from "./components/MusicPlayer";
import MediaQuery from "./components/utils/MediaQuery";
import Modal from "./components/utils/Modal";
import { ChromecastProvider } from "./contexts/ChromeCastContext";
import { useSwipeable } from "react-swipeable";

import {
  MediaPlayerProvider,
  useMediaPlayer
} from "./contexts/MediaPlayerContext";

const getTrackStyle = (track) => {
  const { isIntro, isOutro, isInterlude, isRemix, isBonus } = track;
  let className = "";

  if (isIntro) {
    className += " intro";
  } else if (isOutro) {
    className += " outro";
  } else if (isInterlude) {
    className += " interlude";
  } else if (isRemix) {
    className += " remix";
  } else if (isBonus) {
    className += " bonus";
  }

  return className;
};

const TracksList = ({ tracks, selectTrackIndex, selectedTrackIndex }) => {
  return (
    <div className="tracklist-container">
      <div className="tracklist">
        {tracks.map((track, i) => (
          <div
            key={track.title + " " + i}
            className={`track ${
              selectedTrackIndex === i ? "selected" : ""
            } ${getTrackStyle(track)}`}
            onClick={() => selectTrackIndex(i)}
          >
            {track.cover && (
              <img
                src={track.cover}
                alt={track.title}
                className="track-cover"
              />
            )}
            <span className="track-title">{track.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

function App() {
  const {
    currentTrackIndex: selectedTrackIndex,
    initUserGesture,
    tracks,
    playSong
  } = useMediaPlayer();
  const tabContentRef = useRef(null);
  const appRef = useRef(null);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [tab, setTab] = useState("details"); // 'details' ou 'tracks'
  const backgroundUrl = album.cover;

  const selectedTrack = tracks[selectedTrackIndex];
  const playedSong = tracks[selectedTrackIndex];

  // set background image to app container
  useEffect(() => {
    appRef.current.style.backgroundImage = `url(${backgroundUrl})`;
  }, [backgroundUrl]);

  useEffect(() => {
    // setIsWelcomeModalOpen(true);
    // return;
    const alreadyVisited = localStorage.getItem("alreadyVisited");
    if (!alreadyVisited) {
      setIsWelcomeModalOpen(true);
      localStorage.setItem("alreadyVisited", "true");
    }
  }, []);

  const handleSetTab = (tab) => {
    setTab(tab);
    scrollToTop();
  };

  const selectTrack = (trackIndex) => {
    playSong(trackIndex);
    handleSetTab("details");
  };

  const scrollToTop = () => {
    if (tabContentRef.current) {
      tabContentRef.current.scrollTop = 0;
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSetTab("tracks"),
    onSwipedRight: () => handleSetTab("details"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="app" ref={appRef}>
      <div className="main-content">
        <Modal
          className="welcome-modal"
          isOpen={isWelcomeModalOpen}
          onClose={() => setIsWelcomeModalOpen(false)}
        >
          <div className="welcome-modal-content">
            <img
              src={album.cover}
              alt={album.title}
              className="album-modal-cover"
            />
            <div className="album-modal-text">
              <div className="mt-2 mb-2">
                <h2>{album.title}</h2>
                <p>{album.description}</p>
                <p>{album.message}</p>
              </div>
              {/* <p>Bienvenue ! Profitez de l'expérience musicale.</p> */}
              <button
                onClick={async () => {
                  setIsWelcomeModalOpen(false);
                  await initUserGesture();
                  playSong(0);
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </Modal>

        <MediaQuery rules={{ maxWidth: 958 }}>
          <>
            <div className="album-header">
              <img
                src={album.cover}
                alt={album.title}
                className="album-cover"
              />
              <div className="player-overlay">
                {playedSong && (
                  <h1 className="current-track-title">{selectedTrack.title}</h1>
                )}
                <MusicPlayer />
              </div>
            </div>
            <div className="tab-buttons">
              <button onClick={() => handleSetTab("details")}>Détails</button>
              <button onClick={() => handleSetTab("tracks")}>
                Liste des Pistes
              </button>
            </div>

            <div {...handlers} className="tab-content" ref={tabContentRef}>
              {tab === "details" && selectedTrack && (
                <TrackDetails track={selectedTrack} />
              )}
              {tab === "tracks" && (
                <TracksList
                  selectedTrackIndex={selectedTrackIndex}
                  tracks={tracks}
                  selectTrackIndex={selectTrack}
                />
              )}
            </div>
          </>
        </MediaQuery>

        <MediaQuery rules={{ minWidth: 959 }}>
          <>
            <div className="left-sidebar">
              <div className="album-header">
                <img
                  src={album.cover}
                  alt={album.title}
                  className="album-cover"
                />
                <h1 className="album-title">{album.title}</h1>
                <p>{album.artist}</p>
              </div>
              <MusicPlayer />

              <TracksList
                tracks={tracks}
                selectTrackIndex={selectTrack}
                selectedTrackIndex={selectedTrackIndex}
              />
            </div>
            <div className="right-content">
              {/* Masquer TrackDetails sur mobile */}
              {selectedTrack && <TrackDetails track={selectedTrack} />}
            </div>
          </>
        </MediaQuery>
      </div>
    </div>
  );
}

export default () => (
  <MediaPlayerProvider currentTracks={tracks}>
    <ChromecastProvider>
      <App />
    </ChromecastProvider>
  </MediaPlayerProvider>
);
