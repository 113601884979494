import React, { useRef, useState, useEffect } from "react";

const AudioVisualizer = ({ audioNode, width = 300, height = 150 }) => {
  const canvasRef = useRef(null);
  const overlayRef = useRef(null);
  const lineRef = useRef(null);
  const [mousePositionX, setMousePositionX] = useState(0);
  let analyser;
  let dataArray;
  let rafId;

  const draw = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    requestAnimationFrame(draw);
    analyser.getByteFrequencyData(dataArray);

    ctx.fillStyle = "rgb(0, 0, 0)";
    ctx.fillRect(0, 0, WIDTH, HEIGHT);

    const barWidth = (WIDTH / dataArray.length) * 2.5;
    let barHeight;
    let x = 0;

    for (let i = 0; i < dataArray.length; i++) {
      barHeight = dataArray[i];
      ctx.fillStyle = "rgb(" + (barHeight + 100) + ",50,50)";
      ctx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight / 2);

      x += barWidth + 1;
    }
  };

  useEffect(() => {
    if (audioNode) {
      analyser = audioNode.context.createAnalyser();
      audioNode.connect(analyser);
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount;
      dataArray = new Uint8Array(bufferLength);

      rafId = requestAnimationFrame(draw);

      return () => {
        cancelAnimationFrame(rafId);
        analyser.disconnect();
      };
    }
  }, [audioNode]);

  return (
    <div style={{ position: "relative", width, height }}>
      <canvas ref={canvasRef} width={width} height={height} />
      <div ref={overlayRef} className="overlay" />
      <div ref={lineRef} className="line" />
    </div>
  );
};

export default AudioVisualizer;
